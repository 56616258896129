export const gamesList = [
  {
    title: 'Galactic Pioneers',
    imgUrl: './games/GalacticPioneers.png',
    url: 'https://mobile.twitter.com/galaxypioneer1',
  },
  {
    title: '11Minutes',
    imgUrl: './games/11minutes.png',
    url: 'https://elevenminutes.io/',
  },
  {
    title: 'Cryptopolis',
    imgUrl: './games/Cryptopolis.png',
    url: 'https://cryptopolisgame.com/',
  },
  {
    title: 'Birbz',
    imgUrl: './games/Birbz.png',
    url: 'https://twitter.com/birbz_nft',
  },
  {
    title: 'Arsenal',
    imgUrl: './games/Arsenal.png',
    url: 'https://arsenal.fabwelt.com/',
  },
  {
    title: 'Binamon',
    imgUrl: './games/Binamon.png',
    url: 'https://binamon.org/',
  },
  {
    title: 'Untitled Platformer',
    imgUrl: './games/Untitled.png',
    url: 'https://untitledplatformer.io/',
  },
  {
    title: 'Toshimon',
    imgUrl: './games/Toshimon.png',
    url: 'https://www.toshimon.io/',
  },
];