import React from 'react';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import Hero from './Modules/Hero';
import Section2 from './Modules/Section2';
import Section3 from './Modules/Section3';
import Section4 from './Modules/Section4';
import Section5 from './Modules/Section5';
import Section6 from './Modules/Section6';
import Section7 from './Modules/Section7';
import GamesShowCase from './Modules/GamesShowcase';
import ProductReviews from './Modules/ProductReviews';
import Footer from './Modules/Footer';
import PageHeader from './Modules/PageHeader/PageHeader';
const useStyles = makeStyles(({ constants, breakpoints }: ITheme) => {
  return createStyles({
    globalWrap: {
      [breakpoints.up(1599)]: {
        maxWidth: '70%',
        left: '50%',
        position: 'relative',
        transform: 'translate(-50%)',
      },
      [breakpoints.up(1199)]: {
        margin: `0 ${constants.desktopMargin}px 0`,
      },
      [breakpoints.up(599)]: {
        margin: `0 ${constants.mobileMargin}px 0`,
      },
    },
  });
});

const HomePage: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <PageHeader />
      <div className={classes.globalWrap}>
        <Hero />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <GamesShowCase />
        <ProductReviews />
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
