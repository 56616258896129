import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';

export const useStyles = makeStyles(({ constants, palette, breakpoints }: ITheme) => {
  return createStyles({
    container: {
      [breakpoints.up(360)]: {
        flexDirection: 'column',
        padding: '1rem 0',
      },
      [breakpoints.up(799)]: {
        minHeight: '30vh',
        padding: '3rem 0',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    doubleColumnWrapper: {
      [breakpoints.up(360)]: {
        flexDirection: 'column',
        padding: '3em 0',
      },
      [breakpoints.up(799)]: {
        flexDirection: 'row',
        minHeight: '25vh',
        width: 'unset',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '90%',
    },
    greyBorder: {
      border: '3px solid #373737',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'flex-start',
      borderRadius: '20px',
      [breakpoints.up(599)]: {
        padding: '3rem .5rem',
      },
      [breakpoints.up(1199)]: {
        padding: '3rem 0',
        width: 'calc(50% - 8px)',
      },
      '&:first-of-type': {
        marginBottom: '2rem',
        [breakpoints.up(799)]: {
          marginRight: '1.25rem',
          marginBottom: 0,
        },
      },
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 2rem',
    },
    columnContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '3rem 2rem',
      [breakpoints.up(599)]: {
        padding: '0 2rem',
      },
      maxWidth: '100%',
    },
    bold: {
      fontWeight: 600,
    },
      primary: {
      color: palette.primary.main,
    },
    reduceLeading: {
      letterSpacing: '-.85px',
    },
    white: {
      color: palette.common.white.main,
    },
    textCentered: {
      textAlign: 'center',
    },
    textLeft: {
      textAlign: 'left',
    },
    titleText: {
      textAlign: 'left',
      fontSize: '24px',
      lineHeight: '30px',
      marginBlockEnd: '0rem',
      [breakpoints.up(799)]: {
        fontSize: '40px',
        lineHeight: '48px',
      },
    },
    centerColumn: {
        alignItems: 'center',
    },
    p: {
      fontSize: '20px',
      lineHeight: '26px',
      color: palette.additional['gray'][5],
      textAlign: 'left',
    },
    h4: {
      marginTop: '.5rem',
      fontSize: '20px',
      lineHeight: '26px',
      color: palette.additional['gray'][5],
      [breakpoints.up(799)]: {
        maxWidth: '70%',
      },
    },
    buttonGroup: {
      display: 'flex',
    },
    noUnderline: {
      textDecoration: 'none',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    primaryBtn: {
      background: palette.primary.main,
      fontSize: '18px',
      borderRadius: '8px',
      lineHeight: '26px',
      marginRight: constants.generalUnit * 2,
      marginTop: constants.generalUnit * 3,
      transition: 'all ease-in-out 0.2s',
      '&:hover, &:active, &:focus': {
        background: '#99E54D',
      },
    },
    secondBtn: {
      border: `1px solid ${palette.additional['gray'][8]}`,
      borderRadius: '8px',
      background: 'transparent',
      color: palette.additional['gray'][5],
      fontSize: '18px',
      lineHeight: '26px',
      marginTop: constants.generalUnit * 3,
      transition: 'all ease-in-out 0.2s',
      '&:hover, &:active, &:focus': {
        background: palette.secondary.main,
      },
    },
  });
});
