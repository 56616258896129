import React from 'react';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import { Button } from '@chainsafe/common-components';
import clsx from 'clsx';
import { useStyles } from '../styles';

const useCustomStyles = makeStyles(({ breakpoints }: ITheme) => {
  return createStyles({
    container: {
      padding: '4rem 1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: 1,
    },
    svg: {
      position: 'absolute',
      width: 'calc(100% - 1vw)',
      left: '50%',
      height: '60vh',
      transform: 'translate(-50%)',
      zindex: '-1',
      [breakpoints.up(360)]: {
        height: '70vh',
      },
      [breakpoints.up(599)]: {
        height: '55vh',
      },
      [breakpoints.up(799)]: {
        minHeight: 'calc(70vh - 120px)',
        height: 'calc(80vh - 120px)',
      },
      [breakpoints.up(1199)]: {
        minHeight: 'calc(60vh - 120px)',
        height: 'calc(70vh - 120px)',
      },
      [breakpoints.up(1460)]: {
        minHeight: 'calc(60vh - 120px)',
        height: 'calc(60vh - 120px)',
      },
      [breakpoints.up(2500)]: {
        width: '99vw',
        minHeight: 'calc(35vh - 120px)',
        height: 'calc(35vh - 120px)',
      },
    },
    heroContentContainer: {
      display: 'flex',
      marginTop: '5vh',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textCenter: {
      textAlign: 'center',
    },
    enlargedTitle: {
      fontSize: '48px',
      lineHeight: '56px',
      [breakpoints.up(799)]: {
        fontSize: '60px',
        lineHeight: '68px',
      },
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '100%',
      [breakpoints.up(799)]: {
        padding: '2rem 0',
        maxWidth: '80%',
      },
      margin: '1rem 0',
    },
  });
});

const Hero: React.FC = () => {
  const customClasses = useCustomStyles();
  const classes = useStyles();

  return (
    <>
      <svg
        className={customClasses.svg}
        preserveAspectRatio='none'
        viewBox='0 0 100'
        xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <linearGradient id='gradient'>
            <stop offset='50%' stopColor='rgb(13, 20, 13)' />
            <stop offset='95%' stopColor='rgb(35, 50, 30)' />
            <animateTransform
              attributeName='gradientTransform'
              attributeType='XML'
              type='rotate'
              from='0 0.2 0.9'
              to='360 0.5 0.5'
              dur='15s'
              repeatCount='indefinite'
            />
          </linearGradient>
        </defs>
        <rect fill='url(#gradient)' x='0' y='0' width='100vw' height='100%' />{' '}
      </svg>
      <div className={customClasses.container}>
        <div className={customClasses.heroContentContainer}>
          <div className={customClasses.textWrapper}>
            <h1
              className={clsx(
                classes.titleText,
                customClasses.textCenter,
                customClasses.enlargedTitle,
                classes.white,
                classes.bold
              )}>
              Build blockchain games.
            </h1>
            <p className={clsx(customClasses.textCenter, classes.p)}>
              Connect to web3. Integrate NFTs. Tap into the power of
              decentralization with our complete toolkit.
            </p>
            <div className={classes.buttonGroup}>
              <Button
                size='large'
                variant='danger'
                className={clsx(classes.bold, classes.primaryBtn)}>
                <a
                  className={clsx(classes.noUnderline)}
                  href='https://share.hsforms.com/1aBkpF11aSLW8XDjuwpDGOg4kcdm'
                  rel='noopener noreferrer'
                  target='_blank'>
                  Request a Demo
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
