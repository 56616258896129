import React from 'react';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import clsx from 'clsx';
import { useStyles } from '../styles';
import { Button } from '@chainsafe/common-components';

export const useCustomStyles = makeStyles(({ breakpoints }: ITheme) => {
  return createStyles({
    container: {
      [breakpoints.up(360)]: {
        flexDirection: 'column',
        padding: '2rem 0',
      },
      [breakpoints.up(799)]: {
        flexDirection: 'row',
        padding: '5rem 0',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    p: {
      [breakpoints.up(799)]: {
        maxWidth: '500px',
        textAlign: 'center',
      },
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      padding: '2rem .5rem',
      [breakpoints.up(799)]: {
        flexDirection: 'row',
      },
      [breakpoints.up(1199)]: {
        padding: '3rem 0',
      },
    },
    columnContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 1rem',
      width: '100%',
      '& > img': {
        width: '100%',
        padding: '1.5rem 0',
      },
      [breakpoints.up(799)]: {
        padding: '2rem 0',
      },
    },
  });
});

const Section5: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  return (
    <div className={customClasses.container}>
      <div className={customClasses.column}>
        <div className={customClasses.columnContentWrapper}>
          <img
            src='marketplace.png'
            alt='ChainSafe Gaming marketplace dashboard with mint, trade, and import NFTs functionality'
          />
          <h2 className={clsx(classes.bold, classes.titleText, classes.white)}>
            Catalyze in-game economies.
          </h2>
          <p className={clsx(customClasses.p, classes.p, classes.textCentered)}>
            Giving players ownership doesn’t have to be complicated. Create
            in-game assets in batches with the{' '}
            <span className={clsx(classes.white, classes.bold)}> minter</span>.
            Drive growth with an embedded or external{' '}
            <span className={clsx(classes.white, classes.bold)}>
              {' '}
              marketplace
            </span>
            ..{' '}
          </p>
          <Button
            size='large'
            variant='danger'
            className={clsx(classes.bold, classes.primaryBtn)}>
            <a
              className={classes.noUnderline}
              href='https://marketplace.chainsafe.io/'
              rel='noopener noreferrer'
              target='_blank'>
              Go To Marketplace
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Section5;
