export const loop1:Image[] = [];
export const loop2:Image[] = [];
export const loop3:Image[]= [];
export const loop4:Image[] = [];


export interface Image {
  imgUrl: string;
}

const dir1 = require.context('../../public/tiles/loop1', true, /\.(png|jpe?g|svg)$/)
const dir2 = require.context('../../public/tiles/loop2', true, /\.(png|jpe?g|svg)$/)
const dir3 = require.context('../../public/tiles/loop3', true, /\.(png|jpe?g|svg)$/)
const dir4 = require.context('../../public/tiles/loop4', true, /\.(png|jpe?g|svg)$/)

const addNewImages = (dir, arr, dirname) => {dir.keys().forEach((key) => {
  arr.push({imgUrl: `tiles/${dirname}/` + key.substring(2)})
})
  return arr;
}


addNewImages(dir1, loop1, 'loop1');
addNewImages(dir2, loop2, 'loop2');
addNewImages(dir3, loop3, 'loop3');
addNewImages(dir4, loop4, 'loop4');