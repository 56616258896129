import * as React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import { MobileMenuItem } from './MobileMenuItem';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import { headerLinks } from '../../../data/navLinks';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export interface NavLink {
  id?: number;
  name: string;
  linkUrl: string;
}

const useStyles = makeStyles(({ palette, constants, breakpoints }: ITheme) => {
  return createStyles({
    navMenu: {
      top: '100px',
      position: 'absolute',
      right: '0',
      padding: '25px',
      maxWidth: '80%',
    },
  });
});

export const MobileMenu = () => {
  const classes = useStyles();
  return (
    <motion.ul variants={variants} className={classes.navMenu}>
      {headerLinks.map(({ to, label }, i) => (
        <MobileMenuItem name={label} linkUrl={to} key={i} />
      ))}
    </motion.ul>
  );
};
