import React from 'react';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import clsx from 'clsx';
import { gamesList } from '../../data/gamesList';
import { useStyles } from '../styles';

export const useCustomStyles = makeStyles(
  ({ palette, constants, breakpoints }: ITheme) => {
    return createStyles({
      container: {
        [breakpoints.up(360)]: {
          flexDirection: 'column',
          padding: '2rem 0',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundOrigin: 'center',
      },
      column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        padding: '2rem .5rem',
        [breakpoints.up(799)]: {
          flexDirection: 'row',
        },
        [breakpoints.up(1199)]: {
          padding: '3rem 0',
        },
      },
      columnContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 1rem',
        width: '100%',
        [breakpoints.up(799)]: {
          padding: '2rem 0',
        },
      },
      gamesWrapper: {
        display: 'grid',
        padding: '1rem 0',
        width: '100%',
        gridTemplateColumns: 'repeat(2, 1fr)',
        [breakpoints.up(599)]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
          width: 'unset',
          gridGap: '.25rem',
        },
        alignContent: 'center',
        [breakpoints.up(1099)]: {
          gridTemplateColumns: 'repeat(4, 1fr)',
        },
        '& > a': {
          '&:hover': {
            filter: 'brightness(90%)',
          },
          cursor: 'pointer',
        },
      },
      game: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1rem',
        borderRadius: '18px',
        marginBottom: '.5rem',
        [breakpoints.up(799)]: {
          marginBottom: 'unset',
          justifyContent: 'center',
          padding: '1rem',
        },

        '& > img': {
          width: '100%',
          [breakpoints.up(799)]: {
            marginBottom: constants.generalUnit / 2,
          },
        },
        '& > a': {
          fontSize: '18px',
          lineHeight: '20px',
          marginTop: '.25rem',
          color: palette.additional['gray'][5],
          textAlign: 'left',
          cursor: 'pointer',
          '&:hover': {
            filter: 'brightness(80%)',
          },
        },
      },
    });
  }
);

const GamesShowCase: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  return (
    <div className={customClasses.container}>
      <div className={customClasses.column}>
        <div className={customClasses.columnContentWrapper}>
          <h2 className={clsx(classes.bold, classes.titleText, classes.white)}>
            Made with ChainSafe Gaming
          </h2>
          <div className={customClasses.gamesWrapper}>
            {gamesList.map(({ title, imgUrl, url }, i) => (
              <div key={title + i} className={customClasses.game}>
                <img
                  src={imgUrl}
                  alt={`${title} is built using ChainSafe Gamings web3.unity SDK`}
                />
                <a
                  className={classes.noUnderline}
                  href={url}
                  target='_blank'
                  rel='noopener noreferrer'>
                  <p>{title}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamesShowCase;
