import React from 'react';
import {
  createStyles,
  ITheme,
  makeStyles,
  useMediaQuery,
} from '@chainsafe/common-theme';
import { Grid, Typography } from '@chainsafe/common-components';
import { MobileNav } from './MobileNav';
import { headerLinks } from '../../../data/navLinks';

const useStyles = makeStyles(
  ({ breakpoints, palette, zIndex, constants }: ITheme) => {
    return createStyles({
      container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: constants.generalUnit * 9,
        position: 'absolute',
        // padding: '0.5rem 0',
        zIndex: zIndex?.layer4,
        [breakpoints.down('1170px')]: {
          justifyContent: 'flex-end',
        },
        [breakpoints.down('sm')]: {
          padding: 'none',
        },
      },
      linksMenu: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      logo: {
        width: constants.generalUnit * 4,
        height: constants.generalUnit * 4,
        margin: constants.generalUnit,
      },
      brandName: {
        color: palette.common.white.main,
        marginLeft: constants.generalUnit,
        textDecoration: 'none',
      },
      navlink: {
        color: palette.common.white.main,
        textDecoration: 'none',
        fontWeight: 'bold',
        '&:hover': {
          color: palette.primary.main,
          transition: 'ease-in 0.2s',
        },
        [breakpoints.up('sm')]: {
          marginRight: `${constants.generalUnit * 2}px`,
        },
      },
      flex: {
        display: 'flex',
        alignItems: 'center',
      },
    });
  }
);

const PageHeader: React.FC = () => {
  const classes = useStyles();

  const isMobile = useMediaQuery('(max-width: 1170px)');

  return (
    <nav className={classes.container}>
      {!isMobile && (
        <Grid item alignItems='center' className={classes.linksMenu}>
          <Grid container justifyContent='flex-start' alignItems='center'>
            <Grid item alignItems='flex-start'>
              <div className={classes.flex}>
                <img
                  className={classes.logo}
                  src='/chainsafe_logo.png'
                  alt=''
                />
                <Typography variant='h5'>
                  <a href='/' className={classes.navlink}>
                    ChainSafe Gaming
                  </a>
                </Typography>
              </div>
            </Grid>
            {headerLinks.map(({ to, label }, i) => (
              <Typography variant='h5' key={i}>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={to}
                  className={classes.navlink}>
                  {label}
                </a>
              </Typography>
            ))}
          </Grid>
        </Grid>
      )}
      {isMobile && (
        <Grid
          container
          justifyContent='flex-start'
          alignItems='center'
          className={classes.linksMenu}>
          <Grid item>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img className={classes.logo} src='/chainsafe_logo.png' alt='' />
              <Typography variant='h5'>
                <a href='/' className={classes.navlink}>
                  ChainSafe Gaming
                </a>
              </Typography>
            </div>
          </Grid>
          <Grid item alignItems='flex-end'>
            <MobileNav />
          </Grid>
        </Grid>
      )}
    </nav>
  );
};

export default PageHeader;
