import React from 'react';
import { Switch, Route } from '@chainsafe/common-components';
import HomePage from './HomePage';

export const ROUTE_LINKS = {
  Home: '/',
  HomeLang: (lang = ':lang') => `/${lang}`,
};

const Routes = () => {
  return (
    <Switch>
      <Route exact path={ROUTE_LINKS.Home} component={HomePage} />
    </Switch>
  );
};

export default Routes;
