export const headerLinks = [
  {
    label: 'GitHub',
    to: 'https://github.com/ChainSafe/web3.unity',
  },
  {
    label: 'Marketplace',
    to: 'https://marketplace.chainsafe.io/',
  },
  {
    label: 'Docs',
    to: 'https://docs.gaming.chainsafe.io/',
  },
  {
    label: 'Project Dashboard',
    to: 'https://dashboard.gaming.chainsafe.io/',
  },
]

export const resources = [
  {
    label: 'Download SDK',
    to: 'https://github.com/ChainSafe/web3.unity/releases',
  },
  {
    label: 'Marketplace',
    to: 'https://marketplace.chainsafe.io/',
  },
  {
    label: 'Project Dashboard',
    to: 'https://dashboard.gaming.chainsafe.io/',
  },
  {
    label: 'Documentation',
    to: 'https://docs.gaming.chainsafe.io/',
  },
  {
    label: 'Tutorials',
    to: 'https://www.youtube.com/playlist?list=PLPn3rQCo3XrOQkC3v55Ou8NMPgn8pb7O5',
  },
  {
    label: 'About ChainSafe',
    to: 'https://chainsafe.io/',
  },
  {
    label: 'Brand Assets',
    to: 'csg-brand-assets.zip',
  },
]

export const communityResources = [
    {
    label: 'GitHub',
    to: 'https://github.com/ChainSafe/web3.unity',
  },
  {
    to: 'https://discord.com/invite/5938pUhMee',
    label: 'Discord'
  },
  {
    to: 'https://twitter.com/ChainSafeGaming',
    label: 'Twitter'
  },
  {
    to: 'https://blog.chainsafe.io/tagged/gaming',
    label: 'Blog'
  },
  {
    to: 'https://www.linkedin.com/products/chainsafe-systems-chainsafe-gaming-sdk/',
    label: 'LinkedIn'
  },
  {
    to: 'mailto:info@chainsafe.io',
    label: 'Contact Us'
  },
]