export const productReviews = [
  {
    from: 'Cryptopolis',
    review: '"Using it allowed us to hit the ground running with blockchain development and communication within a week, which, compared to alternatives, is pretty unprecedented. It\'s a powerful and simple tool. It can do everything other tools can but with much less effort. We\'d absolutely recommend it to others."',
  },
  {
    from: 'Fabwelt (Arsenal)',
    review: '"Our experience building Arsenal was smooth, with very mature technology integration due to ChainSafe Gaming SDK."',
  },
  {
    from: '11Minutes',
    review: '"The ChainSafe SDK gives us the power to use different wallets as an authorization method with ease. It is responsible for ensuring that we match all non-fungible token (NFT) tickets in your possession with our leaderboard."',
  },
];