import React from 'react';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import { Grid, Typography } from '@chainsafe/common-components';
import { communityResources, resources } from '../../data/navLinks';

const useStyles = makeStyles(({ palette, constants, breakpoints }: ITheme) => {
  return createStyles({
    root: {
      padding: '2rem 0 2rem 0',
      [breakpoints.up('xl')]: {
        maxWidth: '2560px',
        padding: `80px 72px 80px 72px`,
      },
      [breakpoints.up('3800')]: {
        maxWidth: '100%',
      },
      background: palette.secondary.dark,
      paddingLeft: constants.generalUnit * 2,
    },
    linkWrapper: {
      display: 'flex',
      flexGrow: 0,
      flexBasis: '200px',
      color: palette.primary.main,
      margin: '0 0 .5rem 0',
      '&:hover': {
        color: '#456D1D',
        transition: 'ease-in 0.2s',
      },
      '& a': {
        color: palette.additional['gray'][3],
        textDecoration: 'none',
        '&:hover': {
          color: palette.additional['gray'][7],
          transition: 'ease-in 0.2s',
        },
      },
      [breakpoints.down('sm')]: {
        flexGrow: 1,
        flexBasis: '100%',
      },
      [breakpoints.down('xl')]: {
        margin: '1rem',
      },
      [breakpoints.up('xl')]: {
        flexBasis: '400px',
      },
    },
    bold: {
      fontWeight: 600,
    },
    header: {
      display: 'block',
      paddingBottom: constants.generalUnit,
      flex: 0,
    },
    signupLinkWrapper: {
      maxWidth: '100%',
    },
    item: {
      display: 'block',
      flex: 0,
      paddingBottom: constants.generalUnit,
    },
    smalltextContainer: {
      [breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: constants.generalUnit,
      },
    },
    signupLink: {
      textDecoration: 'none',
      transition: '0.2s ease-in-out',
      '&:hover': {
        color: '#456D1D',
      },
    },
    copyright: {
      position: 'absolute',
      color: palette.additional['gray'][9],
      fontSize: '.8rem',
      [breakpoints.down('xl')]: {
        marginLeft: constants.generalUnit * 2,
      },
      [breakpoints.up('xl')]: {
        textAlign: 'left',
        fontSize: '1rem',
        color: palette.additional['gray'][7],
      },
    },
  });
});

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <Grid container>
        <Grid item className={classes.linkWrapper}>
          <Grid item className={classes.header}>
            <Typography variant='h5'>
              <strong> Resources</strong>
            </Typography>
          </Grid>
          {resources.map(({ to, label }, i) => (
            <Grid item className={classes.item} key={i}>
              <a href={to} rel='noopener noreferrer' target='_blank'>
                <Typography variant='h5' className={classes.bold}>
                  {label}
                </Typography>
              </a>
            </Grid>
          ))}
        </Grid>
        <Grid item className={classes.linkWrapper}>
          <Grid item className={classes.header}>
            <Typography variant='h5' className={classes.bold}>
              <strong>Community</strong>
            </Typography>
          </Grid>
          {communityResources.map(({ to, label }, i) => (
            <Grid item className={classes.item} key={i}>
              <a href={to} target='_blank' rel='noopener noreferrer'>
                <Typography variant='h5' className={classes.bold}>
                  {label}
                </Typography>
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        md={4}
        xl={4}
        justifyContent='flex-start'
        className={classes.smalltextContainer}>
        <Grid item>
          <Typography
            component='p'
            variant='body1'
            className={classes.copyright}>
            &copy; {currentYear} ChainSafe Systems, All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};
export default Footer;
