import React from 'react';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import { Button } from '@chainsafe/common-components';
import clsx from 'clsx';

const useStyles = makeStyles(({ constants, palette, breakpoints }: ITheme) => {
  return createStyles({
    container: {
      [breakpoints.up(360)]: {
        flexDirection: 'column-reverse',
        padding: '3em 0',
      },
      [breakpoints.up(799)]: {
        flexDirection: 'row',
        minHeight: '30vh',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'left',
      [breakpoints.up(599)]: {
        padding: '1rem .5rem',
      },
      [breakpoints.up(1199)]: {
        padding: '3rem 0',
        width: 'calc(50% - 8px)',
      },
      '&:first-of-type': {
        marginBottom: '2rem',
        [breakpoints.up(799)]: {
          marginRight: '1rem',
          marginBottom: 0,
        },
      },
      '& > img': {
        maxWidth: '100%',
        [breakpoints.up(799)]: {
          maxHeight: '400px',
        },
      },
    },
    columnContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0 1rem',
      maxWidth: '100%',
    },
    bold: {
      fontWeight: 600,
    },
    reduceLeading: {
      letterSpacing: '-.85px',
    },
    white: {
      color: palette.common.white.main,
    },
    titleText: {
      textAlign: 'left',
      fontSize: '24px',
      lineHeight: '30px',
      [breakpoints.up(799)]: {
        fontSize: '40px',
        lineHeight: '44px',
        maxWidth: '500px',
      },
    },
    p: {
      fontSize: '20px',
      lineHeight: '26px',
      color: palette.additional['gray'][5],
      [breakpoints.up(799)]: {
        maxWidth: '480px',
      },
    },
    buttonGroup: {
      display: 'flex',
    },
    noUnderline: {
      textDecoration: 'none',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    secondBtn: {
      border: `1px solid ${palette.additional['gray'][8]}`,
      borderRadius: '8px',
      background: 'transparent',
      color: palette.primary.main,
      fontSize: '18px',
      lineHeight: '26px',
      marginTop: constants.generalUnit * 3,
      transition: 'all ease-in-out 0.2s',
      '&:hover, &:active, &:focus': {
        background: palette.additional['gray'][9],
      },
    },
    primary: {
      color: palette.primary.main,
    },
  });
});

const Section4: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <div className={classes.columnContentWrapper}>
          <h1
            className={clsx(
              classes.reduceLeading,
              classes.bold,
              classes.titleText,
              classes.white
            )}>
            A better way to build blockchain-enabled games.
          </h1>
          <p className={classes.p}>
            A great game is born long before the first play-through. The{' '}
            <span className={classes.white}>web3.unity SDK</span> has tools
            designed especially for the job.
          </p>
          <div className={classes.buttonGroup}>
            <Button
              size='large'
              variant='danger'
              className={clsx(classes.bold, classes.secondBtn)}>
              <a
                className={clsx(classes.primary, classes.noUnderline)}
                href='https://docs.gaming.chainsafe.io/'
                rel='noopener noreferrer'
                target='_blank'>
                Start Building
              </a>
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.column}>
        <img
          src='/section41.png'
          alt='A virtual demo showcasing how to install the ChainSafe Gaming web3.unity SDK and integrate it with the Unity game engine'
        />
      </div>
    </div>
  );
};

export default Section4;
