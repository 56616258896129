import React from 'react';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import { useStyles } from '../styles';
import clsx from 'clsx';
import { Button } from '@chainsafe/common-components';
import { consultingFeatures } from '../../data/featureList';

export const useCustomStyles = makeStyles(
  ({ palette, constants, breakpoints }: ITheme) => {
    return createStyles({
      container: {
        [breakpoints.up(360)]: {
          flexDirection: 'column',
          padding: '2rem 0',
        },
        [breakpoints.up(1400)]: {
          background: 'url("/bg2.png")',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'url("/bg1.png")',
        backgroundOrigin: 'center',
      },
      p: {
        padding: '1rem 0',
        [breakpoints.up(799)]: {
          maxWidth: '500px',
          textAlign: 'center',
        },
      },
      column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        padding: '2rem .5rem',
        [breakpoints.up(799)]: {
          flexDirection: 'row',
        },
        [breakpoints.up(1199)]: {
          padding: '3rem 0',
        },
      },
      columnContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 1rem',
        width: '100%',
        [breakpoints.up(799)]: {
          padding: '2rem 0',
        },
      },
      servicesWrapper: {
        display: 'grid',
        padding: '2rem 0',
        gridTemplateColumns: 'minmax(100%, 1fr)',
        width: '100%',
        [breakpoints.up(599)]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
          width: 'unset',
          gridGap: '.25rem',
        },
        alignContent: 'center',
        [breakpoints.up(1199)]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: '.3rem',
        },
      },
      service: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '1rem 2rem',
        borderRadius: '18px',
        marginBottom: '.5rem',
        [breakpoints.up(799)]: {
          marginBottom: 'unset',
          justifyContent: 'center',
          padding: '1.5rem 1.2rem',
        },

        '& > img': {
          width: '24px',
          [breakpoints.up(799)]: {
            marginBottom: constants.generalUnit / 2,
          },
        },
        '& > p': {
          fontSize: '18px',
          lineHeight: '20px',
          marginLeft: '.5rem',
          fontWeight: 600,
          color: palette.additional['gray'][5],
          textAlign: 'left',
          [breakpoints.up(799)]: {
            width: '300px',
          },
        },
      },
    });
  }
);

const Section6: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  return (
    <div className={customClasses.container}>
      <div className={customClasses.column}>
        <div className={customClasses.columnContentWrapper}>
          <h2 className={clsx(classes.bold, classes.titleText, classes.white)}>
            Integrate with peace of mind.
          </h2>
          <p className={clsx(customClasses.p, classes.p, classes.textCentered)}>
            Support your project's vision with our around-the-clock consulting
            and R&D services.
          </p>
          <Button
            size='large'
            variant='danger'
            className={clsx(classes.bold, classes.primaryBtn)}>
            <a
              className={classes.noUnderline}
              href='https://cta-redirect.hubspot.com/cta/redirect/7667626/792b6bb5-c37e-49bc-9864-4dbcb36e0b39'
              rel='noopener noreferrer'
              target='_blank'>
              Schedule a Call
            </a>
          </Button>
          <div className={customClasses.servicesWrapper}>
            {consultingFeatures.map(({ name, img }, i) => (
              <div
                key={name + i}
                className={clsx(classes.greyBorder, customClasses.service)}>
                <img src={img} alt='' />
                <p>{name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
