import React from 'react';
import { Button } from '@chainsafe/common-components';
import clsx from 'clsx';
import { useStyles } from '../styles';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import { sdkFeatures, marketplaceFeatures } from '../../data/featureList';

export const useCustomStyles = makeStyles(({ breakpoints }: ITheme) => {
  return createStyles({
    h2: {
      textAlign: 'center',
      fontSize: '24px',
      lineHeight: '30px',
      [breakpoints.up(799)]: {
        fontSize: '40px',
        lineHeight: '44px',
      },
    },
    featureList: {
      display: 'flex',
      padding: '1.25rem 0',
      flexDirection: 'column',
    },
    feature: {
      display: 'flex',
      alignItems: 'center',
      '& > img': {
        width: 40,
        height: 40,
        margin: '.3rem .75rem',
      },
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: '20px',
      [breakpoints.up(599)]: {
        padding: '3rem .5rem',
      },
      [breakpoints.up(1199)]: {
        padding: '3rem 0',
        width: 'calc(50% - 8px)',
      },
      '&:first-of-type': {
        marginBottom: '2rem',
        [breakpoints.up(799)]: {
          marginRight: '1.25rem',
          marginBottom: 0,
        },
      },
    },
    btnGroup: {
      display: 'flex',
      flexDirection: 'column',
      '& > button': {
        width: '100%',
        margin: '.5rem 0',
      },
    },
  });
});

const Section3: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        <h1 className={clsx(classes.bold, classes.white, customClasses.h2)}>
          A comprehensive solution.
        </h1>
        <p className={clsx(classes.textCentered, classes.p)}>
          Get started with <span>ChainSafe Gaming:</span>
        </p>
      </div>
      <div className={classes.doubleColumnWrapper}>
        <div className={clsx(classes.greyBorder, customClasses.column)}>
          <div
            className={clsx(
              classes.centerColumn,
              classes.columnContentWrapper
            )}>
            <h2
              className={clsx(
                classes.reduceLeading,
                classes.bold,
                classes.white,
                customClasses.h2
              )}>
              web3.unity
            </h2>
            <h4 className={clsx(classes.textCentered, classes.h4)}>
              Seamlessly connect your games with the web3 experience.
            </h4>
            <div className={customClasses.featureList}>
              {sdkFeatures.map((i) => (
                <div key={i} className={customClasses.feature}>
                  <img src='/checkmark.png' alt='' />
                  <p className={classes.p}>{i}</p>
                </div>
              ))}
            </div>
            <div className={customClasses.btnGroup}>
              <Button
                size='large'
                variant='danger'
                className={clsx(classes.bold, classes.primaryBtn)}>
                <a
                  className={classes.noUnderline}
                  href='https://github.com/ChainSafe/web3.unity/releases/latest/download/web3.unitypackage'
                  rel='noopener noreferrer'
                  target='_blank'>
                  Download Unity Package (Direct)
                </a>
              </Button>
            </div>
          </div>
        </div>
        <div className={clsx(classes.greyBorder, customClasses.column)}>
          <div
            className={clsx(
              classes.centerColumn,
              classes.columnContentWrapper
            )}>
            <h2
              className={clsx(
                classes.reduceLeading,
                classes.bold,
                classes.white,
                customClasses.h2
              )}>
              Marketplace
            </h2>
            <h4 className={clsx(classes.textCentered, classes.h4)}>
              For studios and players to create and trade in-game assets.
            </h4>
            <div className={customClasses.featureList}>
              {marketplaceFeatures.map((i) => (
                <div key={i} className={customClasses.feature}>
                  <img src='/checkmark.png' alt='' />
                  <p className={clsx(classes.textLeft, classes.p)}>{i}</p>
                </div>
              ))}
            </div>
            <div className={classes.buttonGroup}>
              <div className={customClasses.btnGroup}>
                <Button
                  size='large'
                  variant='danger'
                  className={clsx(classes.bold, classes.primaryBtn)}>
                  <a
                    className={classes.noUnderline}
                    href='https://marketplace.chainsafe.io/'
                    rel='noopener noreferrer'
                    target='_blank'>
                    Go to Marketplace UI
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
