import React, { useEffect, useCallback, useState } from 'react';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import clsx from 'clsx';
import { loop1, loop2, loop3, loop4 } from '../../data/images';

const useStyles = makeStyles(({ constants, palette, breakpoints }: ITheme) => {
  return createStyles({
    container: {
      [breakpoints.up(360)]: {
        flexDirection: 'column-reverse',
        padding: '10rem 0',
      },
      [breakpoints.up(799)]: {
        padding: '4em 0',
        flexDirection: 'row',
        minHeight: '30vh',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'left',
      [breakpoints.up(599)]: {
        padding: '2rem .5rem',
      },
      [breakpoints.up(1199)]: {
        width: 'calc(50% - 8px)',
      },
      '&:first-of-type': {
        marginBottom: '2rem',
        [breakpoints.up(799)]: {
          marginRight: '1rem',
          marginBottom: 0,
        },
      },
    },
    img: {
      height: 90,
      width: 90,
      [breakpoints.up(799)]: {
        height: 120,
        width: 120,
      },
    },
    columnContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0 1rem',
      maxWidth: '100%',
    },
    bold: {
      fontWeight: 600,
    },
    reduceLeading: {
      letterSpacing: '-.85px',
    },
    white: {
      color: palette.common.white.main,
    },
    titleText: {
      textAlign: 'left',
      fontSize: '24px',
      lineHeight: '30px',
      [breakpoints.up(799)]: {
        fontSize: '40px',
        lineHeight: '44px',
      },
    },
    p: {
      fontSize: '20px',
      lineHeight: '26px',
      color: palette.additional['gray'][5],
    },
    buttonGroup: {
      display: 'flex',
    },
    noUnderline: {
      textDecoration: 'none',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
  });
});

const Section2: React.FC = () => {
  const classes = useStyles();
  const [image, setImg] = useState(loop1[0]);
  const [image2, setImg2] = useState(loop2[0]);
  const [image3, setImg3] = useState(loop3[0]);
  const [image4, setImg4] = useState(loop4[0]);

  const getRandomIndex = (arr) => Math.floor(Math.random() * arr.length);

  const shuffle = useCallback(() => {
    setImg(loop1[getRandomIndex(loop1)]);
    setImg2(loop2[getRandomIndex(loop2)]);
  }, []);
  const shuffle2 = useCallback(() => {
    setImg3(loop3[getRandomIndex(loop3)]);
    setImg4(loop4[getRandomIndex(loop4)]);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(shuffle, 1000);
    return () => clearInterval(intervalId);
  }, [shuffle]);

  useEffect(() => {
    const intervalId = setInterval(shuffle2, 1600);
    return () => clearInterval(intervalId);
  }, [shuffle2]);

  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <div className={classes.columnContentWrapper}>
          <h1 className={clsx(classes.bold, classes.titleText, classes.white)}>
            Making web3 easy, fun, and accessible.
          </h1>
          <p className={classes.p}>
            From digital collectibles to in-game assets, incentive mechanisms,
            and everything in between, change how your players own, create, and
            get rewarded with ChainSafe Gaming.
          </p>
        </div>
      </div>
      <div className={classes.column}>
        <div className={classes.row}>
          <img className={classes.img} src='/tiles/03.png' alt='' />
          <img className={classes.img} src={image.imgUrl} alt='' />
          <img className={classes.img} src='/tiles/02.png' alt='' />
          <img className={classes.img} src={image3.imgUrl} alt='' />
        </div>
        <div className={classes.row}>
          <img className={classes.img} src='/tiles/01.png' alt='' />
          <img className={classes.img} src='/tiles/09.png' alt='' />
          <img className={classes.img} src={image2.imgUrl} alt='' />
          <img className={classes.img} src='/tiles/10.png' alt='' />
        </div>
        <div className={classes.row}>
          <img className={classes.img} src={image4.imgUrl} alt='' />
          <img className={classes.img} src='/tiles/05.png' alt='' />
          <img className={classes.img} src='/tiles/07.png' alt='' />
          <img className={classes.img} src='/tiles/08.png' alt='' />
        </div>
      </div>
    </div>
  );
};

export default Section2;
