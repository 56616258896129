import React from 'react';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import clsx from 'clsx';
import { useStyles } from '../styles';
import { stats } from '../../data/featureList';

const useCustomStyles = makeStyles(
  ({ constants, palette, breakpoints }: ITheme) => {
    return createStyles({
      container: {
        [breakpoints.up(360)]: {
          flexDirection: 'column',
          padding: '3em 0',
        },
        [breakpoints.up(799)]: {
          flexDirection: 'row',
          minHeight: '30vh',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'left',
        [breakpoints.up(599)]: {
          padding: '2rem .5rem',
        },
        [breakpoints.up(1199)]: {
          width: 'calc(50% - 8px)',
        },
        '&:nth-of-type(2)': {
          alignItems: 'flex-start',
        },
      },
      columnContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0 1rem',
        maxWidth: '90%',
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
      },
      tiles: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: '.5rem',
        marginBottom: '1rem',
      },
      tile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        background: '#0F0F0F',
        borderRadius: '20px',
        padding: '1.5rem 1rem',
        minHeight: '200px',
        [breakpoints.up(799)]: {
          width: '200px',
        },
        '& > h4': {
          textAlign: 'left',
          fontSize: '40px',
          lineHeight: '40px',
          fontWeight: 600,
          color: palette.primary.main,
          marginBottom: '.5rem',
        },
      },
    });
  }
);

const Section7: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  return (
    <div className={customClasses.container}>
      <div className={customClasses.column}>
        <div className={customClasses.tiles}>
          {stats.map(({ stat, label }, i) => (
            <div key={i} className={customClasses.tile}>
              <h4>{stat}</h4>
              <p className={classes.p}>{label}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={customClasses.column}>
        <div className={customClasses.columnContentWrapper}>
          <h1 className={clsx(classes.bold, classes.titleText, classes.white)}>
            Power your game with the best technology.
          </h1>
          <p className={classes.p}>
            ChainSafe Gaming leads web3 technology and development with tooling
            that empowers game studios and developers to experiment freely with
            an SDK that seamlessly integrates blockchains with the Unity engine.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section7;
