import group from '../Components/Modules/Icons/group.svg'
import laptop from '../Components/Modules/Icons/laptop.svg'
import nft from '../Components/Modules/Icons/nft.svg'
import trending from '../Components/Modules/Icons/trending.svg'
import wallet from '../Components/Modules/Icons/wallet.svg'
import loader from '../Components/Modules/Icons/loader.svg'

export const sdkFeatures = [
  'Multi-chain',
  'Easy web3 sign-in',
  'Custom smart contract calls',
  'Multi-wallet support',
  'Works for desktop, mobile, and web',
]
export const marketplaceFeatures = [
  'Access in-browser or through Unity',
  'Embeddable in-game marketplace',
  'Open source and customizable',
  'Anti-cheat vouchers',
  'Supports collections and royalties',
]

export const consultingFeatures = [
  {
    name: 'Faster time-to-market',
    img: loader
  },
  {
    name: 'Technical evaluation & chain selection',
    img: laptop
  },
  {
    name: 'GameFi design & conceptualization',
    img: nft
  },
  {
    name: 'Unity & blockchain support',
    img: group
  },
  {
    name: 'Security auditing',
    img: wallet,
  },
  {
    name: 'Funding & business development',
    img: trending
  },
]

export const stats = [
  {
    stat: '100+',
    label: 'Games using CSG in production.',
  },
  {
    stat: '6,100+',
    label: 'Builders on Discord.',
  },
  {
    stat: '700+',
    label: 'GitHub stars.',
  },
  {
    stat: 'Forever',
    label: 'Open-source and no vendor lock-in.',
  },
]