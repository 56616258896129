import React, { useState } from 'react';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import clsx from 'clsx';
import arrowRight from './Icons/arrowRight.svg';
import arrowLeft from './Icons/arrowLeft.svg';
import { productReviews } from '../../data/reviewsList';
import { useStyles } from '../styles';
import { Button } from '@chainsafe/common-components';

const useCustomStyles = makeStyles(
  ({ breakpoints, palette, zIndex, constants }: ITheme) => {
    return createStyles({
      container: {
        padding: '1rem 0 2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
      column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        justifyContent: 'center',
        position: 'relative',
        padding: '2rem 1rem',
        [breakpoints.up(799)]: {
          padding: '1.5rem',
          width: '60%',
        },
        [breakpoints.up(1299)]: {
          padding: '1.5rem',
          width: '50%',
        },
      },
      slideWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: '100%',
        opacity: 0,
        transitionDuration: '1s ease',
      },
      currentSlide: {
        opacity: 1,
        transitionDuration: '1s',
      },
      textWrapper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '1rem 0 3rem',
        [breakpoints.up(1299)]: {
          marginLeft: '2rem',
        },
        [breakpoints.up(799)]: {
          marginLeft: '2rem',
        },
      },
      titleText: {
        color: palette.common.white.main,
        fontSize: '34px',
        lineHeight: '40px',
        fontWeight: 600,
        padding: '1rem 0',
      },
      icon: {
        position: 'absolute',
        bottom: '20px',
        width: '50px',
        color: '#000',
        cursor: 'pointer',
        userSelect: 'none',
        zIndex: zIndex?.layer3,
        [breakpoints.up(799)]: {
          bottom: 'unset',
          top: '50%',
        },
        '&:hover': {
          filter: 'brightness(80%)',
        },
      },
      iconLeft: {
        [breakpoints.up(799)]: {
          left: '-40px',
        },
      },
      iconRight: {
        right: constants.generalUnit * 2,
        [breakpoints.up(799)]: {
          right: 'unset',
          left: '100%',
        },
      },
      testCard: {
        padding: '1.5rem',
        background: palette.secondary.dark,
        marginBottom: '1rem',
        borderRadius: '20px',
      },
      testCardText: {
        fontSize: '20px',
        lineHeight: '26px',
        color: palette.additional['gray'][5],
        [breakpoints.up(799)]: {
          maxWidth: '500px',
          padding: '0 2rem',
        },
      },
      testCaptionText: {
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: 600,
        color: palette.primary.main,
        textAlign: 'right',
        [breakpoints.up(799)]: {
          maxWidth: '500px',
          padding: '.5rem 2rem',
        },
        [breakpoints.up(1199)]: {
          textAlign: 'left',
        },
      },
    });
  }
);

const ProductReviews: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const [current, setCurrent] = useState(0);
  const length = productReviews.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(productReviews) || productReviews.length <= 0) {
    return null;
  }

  return (
    <div className={customClasses.container}>
      <div className={customClasses.column}>
        <h2 className={clsx(customClasses.titleText)}>Product Reviews</h2>
        {current === length - 1 ? null : (
          <img
            src={arrowRight}
            onClick={nextSlide}
            className={clsx(customClasses.icon, customClasses.iconRight)}
            alt='arrow Right'
          />
        )}
        {current === 0 ? null : (
          <img
            src={arrowLeft}
            onClick={prevSlide}
            className={clsx(customClasses.iconLeft, customClasses.icon)}
            alt='arrow left'
          />
        )}
        {productReviews.map(({ from, review }, index) => (
          <div
            key={index}
            className={clsx(customClasses.slideWrapper, {
              [customClasses.currentSlide]: index === current,
            })}>
            {index === current && (
              <div key={index} className={customClasses.testCard}>
                <p className={customClasses.testCardText}>{review}</p>
                <p className={customClasses.testCaptionText}>{from}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={classes.buttonGroup}>
        <Button
          size='large'
          variant='danger'
          className={clsx(classes.bold, classes.primaryBtn)}>
          <a
            className={classes.noUnderline}
            href='https://share.hsforms.com/1aBkpF11aSLW8XDjuwpDGOg4kcdm'
            rel='noopener noreferrer'
            target='_blank'>
            Request a Demo
          </a>
        </Button>
        <Button
          size='large'
          variant='danger'
          className={clsx(classes.bold, classes.secondBtn)}>
          <a
            className={clsx(classes.noUnderline, classes.primary)}
            href='https://docs.gaming.chainsafe.io/tutorial'
            rel='noopener noreferrer'
            target='_blank'>
            Start Building
          </a>
        </Button>
      </div>
    </div>
  );
};

export default ProductReviews;
