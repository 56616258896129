import React from 'react';
import { ThemeProvider, createTheme } from '@chainsafe/common-theme';
import { CssBaseline, Router } from '@chainsafe/common-components';
import '@chainsafe/common-theme/dist/font-faces.css';
import Plausible from 'plausible-tracker';
import Routes from './Components/Routes';

const { enableAutoPageviews, enableAutoOutboundTracking } = Plausible({
  domain: 'gaming.chainsafe.io',
  trackLocalhost: false,
});

enableAutoPageviews();
enableAutoOutboundTracking();

const theme = createTheme({
  globalStyling: {
    body: {
      backgroundColor: '#000000',
    },
  },
  themeConfig: {
    palette: {
      primary: {
        main: '#B4FF68',
      },
      secondary: {
        main: '#333333',
        dark: '#161315',
      },
    },
    constants: {
      mobileMargin: 16,
      desktopMargin: 32,
    },
    typography: {},
  },
});

const App: React.FC<{}> = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  );
};

export default App;
